import settings from '../settings'

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home },

  { path: '/loading', component: () => import('@/views/Loading.vue') },

  { path: '/visitor', component: () => import('@/views/Visitor.vue') },

  { path: '/order', component: () => import('@/views/Order.vue') },
  { path: '/shipping', component: () => import('@/views/Shipping.vue') },
  { path: '/shipping-fast', component: () => import('@/views/ShippingFast.vue') },
  { path: '/shipping-form', component: () => import('@/views/ShippingForm.vue') },
  { path: '/invoice', component: () => import('@/views/Invoice.vue') },
  { path: '/payment', component: () => import('@/views/PaymentForm.vue') },
  { path: '/payment/card', component: () => import('@/views/PaymentCard.vue') },
  { path: '/payment/gift-card', component: () => import('@/views/PaymentGiftCard.vue') },
  { path: '/confirm', component: () => import('@/views/Confirm.vue') },
  { path: '/resume', component: () => import('@/views/Resume.vue') },
  { path: '/waiting/:order_id', component: () => import('@/views/Waiting.vue') },

  { path: '/error401', component: () => import('@/views/Error/Error401.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: settings.app().path,
  scrollBehavior() { return { x: 0, y: 0 }; },
  routes
})

export default router
