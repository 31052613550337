import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import settings from './settings'

import BootstrapVue from 'bootstrap-vue'
import './assets/custom.scss'
Vue.use(BootstrapVue)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.config(
  new Date().getTime() + 60 * 60 * 1,
  '/',
  settings.cookies().domain,
  settings.cookies().secure
)

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
Vue.use(VuePlyr, { plyr: {} } )

Vue.config.productionTip = false

new Vue({
  router,
  store,
  settings,
  render: h => h(App)
}).$mount('#app')
