const MODE = 0

const APP_PATH = [ '/', '/' ]

const API_ENDPOINT = [ 'https://pay.x-orsystem.com', 'http://pay.xsys.com' ]
const API_PATH = [ '/api/public', '/api']

const COOKIES_DOMAIN = [ 'x-orsystem.com', 'xsys.com' ]
const COOKIES_SECURE = [ true, false ]

const FILES_PATH = [ '/home4/xorsyste/projects/xsys/files', '' ]

export default {

  app() { return { 
    path: APP_PATH[MODE]
  } },

  api() { return { 
    endpoint: API_ENDPOINT[MODE],
    path: API_PATH[MODE]
  } },

  cookies() { return {
    domain: COOKIES_DOMAIN[MODE],
    secure: COOKIES_SECURE[MODE]
  } },

  files() { return {
    path: FILES_PATH[MODE]
  } },

}