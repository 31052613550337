import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    loading: '',
    token: '',
    customer: '',
    order: '',
    shipping: '',
    invoice: '',
    payment: ''
  },
  mutations: {
    set_loading(state, data) { state.loading = data },
    set_token(state, data) { state.token = data },
    set_customer(state, data) { state.customer = data },
    set_order(state, data) { state.order = data },
    set_order_final(state, data) { state.order_final = data },
    set_shipping(state, data) { state.shipping = data },
    set_invoice(state, data) { state.invoice = data },
    set_payment(state, data) { state.payment = data },
  },
  getters: {
    get_loading(state) { return state.loading },
    get_token(state) { return state.token },
    get_customer(state) { return state.customer },
    get_order(state) { return state.order },
    get_order_final(state) { return state.order_final },
    get_shipping(state) { return state.shipping },
    get_invoice(state) { return state.invoice },
    get_payment(state) { return state.payment }
  }
})

export default store 