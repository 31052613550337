<template>
  <div class="home">

    <app-step step="0" />

  </div>
</template>

<script>
import axios from 'axios'
import settings from '../settings'

export default {
  name: 'Home',
  data() {
    return {
      xwt: '',
      is_validated: '',
    }
  },
  components: {
    AppStep: () => import('@/components/AppStep.vue')
  },
  methods: {
    async validate_customer() {

      var headers = { xwt: this.xwt }

      axios.get(settings.api().endpoint + settings.api().path + '/validate_customer', { headers }).then( (r) => {
        this.is_validated = r.data.data
        if(this.is_validated) this.$router.push('/order')
      })

    }
  },
  created() {
    this.is_validated = false

    this.xwt = this.$cookies.get('xwt')
    if(this.xwt !== null && this.xwt !== '') {
      
      this.$store.commit('set_token', this.xwt)
      this.$store.commit('set_customer', JSON.parse(atob(this.xwt.split('.')[1])) )
      this.$store.commit('set_loading', { is_loading: true, redirect: '/' })

      if(this.$store.getters.get_customer.customer_group_id !== '6') this.validate_customer()
      else {
        this.$router.push('/visitor')
      }

    } else this.$router.push('/error401')
  },
}
</script>
